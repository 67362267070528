export default function sortBudgetOptions(budgetOptions) {
	if (!budgetOptions || budgetOptions.length <= 1) return budgetOptions

	return budgetOptions.sort((a, b) => {
		if (a.customAdSetup !== b.customAdSetup) {
			return a.customAdSetup ? 1 : -1
		}

		if (a.budget === 0) return 1
		if (b.budget === 0) return -1
		if (a.budget !== b.budget) return a.budget - b.budget

		return a.name.localeCompare(b.name, 'nb')
	})
}
