// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

const opts = {
	rtl: false,
	theme: {
		dark: false,
		themes: {
			dark: {
				primary: '#0F72E9',
				accent: '#262626',
				secondary: '#FFFF00',
				success: '#4CAF50',
				info: '#2196F3',
				warning: '#FB8C00',
				error: '#FF5252',
			},
			light: {
				primary: '#0F72E9',
				accent: '#262626',
				secondary: '#FFFF00',
				success: '#4CAF50',
				info: '#2196F3',
				warning: '#FB8C00',
				error: '#FF5252',
			},
		},
	},
	presets: {},
	icons: {
		iconfont: 'mdiSvg',
	},
}

export default new Vuetify(opts)
