import Vue from 'vue'
import App from './App.vue'

let vueObj = {
	render: h => h(App),
}

Vue.config.devtools = false
Vue.config.productionTip = false

if (window.location.pathname.includes('/iframead')) {
	const CustomImg = () => {
		return import(
			/* webpackChunkName: "Custom Image" */ './components/CustomImg.vue'
		)
	}
	Vue.component('Custom-Img', CustomImg)
} else {
	Vue.mixin({
		methods: {
			capitalize(str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
		},
	})

	vueObj.store = require('./store').default
	vueObj.router = require('./router').default
	vueObj.vuetify = require('@/plugins/vuetify').default

	const Fuse = require('vue-fuse')
	Vue.use(Fuse)

	const Notify = require('vue2-notify2').default
	Vue.use(Notify, {
		visibility: 5000,
		position: 'bottom-right',
		itemClass: 'alert-notification',
	})
}

const VueApp = new Vue(vueObj)
VueApp.$mount('#app')
