import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index'
import APIService from '@/services/APIService.js'
import sortBudgetOptions from '@/store/sortBudgetOptions.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loginToken: localStorageParse('skala-login-token'),
		brands: localStorageParse('skala-brands'),
		activeBrand: localStorageParse('skala-active-brand'),
		activeBrandSmall: null,
		activeCompany: localStorageParse('skala-active-company'),
		campaignOrders: null,
		order: {
			budget: 0,
			customLandingPage: null,
			customOrderText: '',
			optionId: null,
		},
		defaultCampaignTypeSizes: [],
	},
	getters: {
		campaignOrderById: state => id => {
			if (!state.campaignOrders) return null
			return state.campaignOrders.find(campaignOrder => campaignOrder.id == id)
		},
	},
	mutations: {
		setLoginToken(state, payload) {
			if (payload == null) {
				localStorage.clear()
				state.loginToken = null
				state.brands = null
				state.activeBrand = null
				state.activeCompany = null
				state.campaignOrders = null
			} else {
				localStorage.setItem('skala-login-token', JSON.stringify(payload))
				state.loginToken = payload
			}
		},
		setUser({ loginToken }, payload) {
			if (!loginToken) return
			if (!payload) {
				loginToken.user = null
				return
			}
			loginToken.user = payload
		},
		setBrands(state, payload) {
			state.brands = payload
			localStorage.setItem('skala-brands', JSON.stringify(payload))
		},
		setActiveBrand(state, payload) {
			state.activeBrand = payload
			localStorage.setItem('skala-active-brand', JSON.stringify(payload))
		},
		setActiveBrandSmall(state, payload) {
			state.activeBrandSmall = payload
		},
		setActiveCompany(state, payload) {
			state.activeCompany = payload
			localStorage.setItem('skala-active-company', JSON.stringify(payload))
		},
		setCampaignOrders(state, payload) {
			state.campaignOrders = payload
		},
		setOrderBudget(state, payload) {
			state.order.budget = payload
		},
		setCustomOrderText(state, payload) {
			state.order.customOrderText = payload
		},
		setCustomLandingPage(state, payload) {
			state.order.customLandingPage = payload
		},
		setOrderOptionId(state, payload) {
			state.order.optionId = payload
		},
		setDefaultCampaignTypeSizes(state, payload) {
			state.defaultCampaignTypeSizes = payload
		},
	},
	actions: {
		// LOGIN
		login({ commit }, { username, password }) {
			var credentials = new FormData()
			credentials.append('username', username)
			credentials.append('password', password)
			return APIService.login(credentials)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn(response.data)
					} else {
						commit('setLoginToken', response.data.data.loginToken)
						// router.push({ name: 'Order' })
					}
					return response
				})
				.catch(error => error.response)
		},
		logout({ commit }) {
			APIService.logout().catch(error => {
				console.warn(error)
			})
			commit('setLoginToken', null)
			router.push({ name: 'Login' })
		},

		// PASSWORD
		passwordReset(context, payload) {
			return APIService.passwordReset(payload)
				.then(response => {
					if (!response) return
					return response
				})
				.catch(error => console.warn(error))
		},
		passwordNew(context, payload) {
			return APIService.passwordNew(payload)
				.then(response => {
					if (!response) return
					return response
				})
				.catch(error => console.warn(error))
		},

		// USERS
		createUser(context, payload) {
			return APIService.createUser(payload)
				.then(response => response)
				.catch(error => console.warn('createUser error:', error))
		},
		updateUser(context, { id, payload }) {
			return APIService.updateUser(id, payload)
				.then(response => response)
				.catch(error => console.warn('updateUser error:', error))
		},
		deleteUser(context, userId) {
			return APIService.deleteUser(userId)
				.then(response => response)
				.catch(error => console.error('deleteUser error:', error))
		},
		updatePassword(context, payload) {
			return APIService.updatePassword(payload)
				.then(response => response)
				.catch(error => console.error('updatePassword error:', error))
		},

		// Budget Options
		createBudgetOption(context, payload) {
			return APIService.createBudgetOption(payload)
				.then(response => response)
				.catch(error => console.warn('deleteBudgetOption error:', error))
		},
		updateBudgetOption(context, { id, payload }) {
			return APIService.updateBudgetOption(id, payload)
				.then(response => response)
				.catch(error => console.warn('deleteBudgetOption error:', error))
		},
		deleteBudgetOption(context, userId) {
			return APIService.deleteBudgetOption(userId)
				.then(response => response)
				.catch(error => console.error('deleteBudgetOption error:', error))
		},

		// BRAND
		myBrands({ state, dispatch, commit }, newBrandId) {
			// if (state.activeBrand && state.activeBrand.id == newBrandId) {
			// 	return
			// }
			let brandId =
				newBrandId || (state.activeBrand && state.activeBrand.id) || ''
			if (newBrandId == -1) {
				brandId = ''
			}
			if (newBrandId) {
				commit('setActiveCompany', null)
			}
			if (state.brands && state.brands.length) {
				const smallBrand = state.brands.find(x => x.id == brandId)
				if (smallBrand) {
					commit('setActiveBrandSmall', smallBrand)
				}
			}
			return APIService.myBrands(brandId)
				.then(response => {
					commit('setActiveBrandSmall', null)
					if (response.data.status != 'success') {
						return response
					}
					commit('setBrands', response.data.data.brands)
					commit('setActiveBrand', response.data.data.brand)
					if (
						!response.data.data.brand ||
						!response.data.data.brand.companies.length
					) {
						commit('setActiveCompany', null)
					} else {
						if (!state.activeCompany) {
							commit('setActiveCompany', response.data.data.brand.companies[0])
						} else {
							if (!state.activeCompany) {
								dispatch('getCompany', response.data.data.brand.companies[0].id)
								return response
							}
							const activeCompanyUpdated = response.data.data.brand.companies.find(
								x => x.id == state.activeCompany.id
							)
							if (!activeCompanyUpdated) {
								dispatch('getCompany', response.data.data.brand.companies[0].id)
							}
						}
					}
					return response
				})
				.catch(() => {
					dispatch('logout')
				})
		},
		createBrand({ dispatch }, payload) {
			return APIService.createBrand(payload)
				.then(async response => {
					if (response.data.status == 'error') {
						console.warn('createBrand error', response.data)
					}
					dispatch('myBrands')
					sortBudgetOptions(response.data.data.brand.budgetOptions)

					return response
				})
				.catch(error => console.warn(error))
		},

		updateBrand({ dispatch }, { id, payload }) {
			return APIService.updateBrand(id, payload)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('updateBrand error', response.data)
					}
					dispatch('myBrands')
					return response
				})
				.catch(error => console.warn(error))
		},
		// COMPANY
		getCompany({ commit }, id) {
			return APIService.getCompany(id)
				.then(response => {
					if (!response) return
					if (response.data.status == 'error') {
						console.error(response)
					} else {
						commit('setActiveCompany', response.data.data.company)
					}
					return response
				})
				.catch(error => console.error(error))
		},
		createCompany({ commit }, payload) {
			return APIService.createCompany(payload)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('createCompany error', response.data)
					} else {
						commit('setActiveCompany', response.data.data.company)
					}
					return response
				})
				.catch(error => console.error(error))
		},
		updateCompany({ dispatch }, { id, payload }) {
			return APIService.updateCompany(id, payload)
				.then(response => {
					if (response.data.status == 'error') {
						console.error('updateCompany error', response)
					}
					dispatch('myBrands')
					return response
				})
				.catch(error => console.error(error))
		},
		deleteCompany({ dispatch }, companyId) {
			return APIService.deleteCompany(companyId)
				.then(response => {
					if (response && response.data.status == 'error') {
						console.warn('deleteCompany error', response.data)
					} else {
						dispatch('myBrands')
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		checkMissingActiveCompany({ dispatch, state }) {
			if (!state.activeCompany) {
				dispatch('setFirstCompanyActive')
				return
			}
			var isActiveCompanyInCurrentBrand = state.activeBrand.companies.some(
				company => company.id == state.activeCompany.id
			)
			if (!isActiveCompanyInCurrentBrand) {
				dispatch('setFirstCompanyActive')
			}
		},
		setFirstCompanyActive({ dispatch, commit, state }) {
			if (!state.activeBrand) return
			var firstCompany = state.activeBrand.companies.find(company => company)
			if (firstCompany) {
				dispatch('getCompany', firstCompany.id)
			} else {
				// console.warn('No companies present in current brand')
				commit('setActiveCompany', null)
			}
		},
		// hydrateActiveCompany({ dispatch, state }) {
		// 	if (!state.activeCompany) {
		// 		dispatch('checkMissingActiveCompany')
		// 	} else {
		// 		if (state.activeCompany && state.activeCompany.id) {
		// 			dispatch('getCompany', state.activeCompany.id)
		// 		}
		// 	}
		// },

		// CAMPAIGNTYPE
		getCampaignType(context, id) {
			return APIService.getCampaignType(id)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('createCampaignType error', response.data)
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		createCampaignType(context, payload) {
			return APIService.createCampaignType(payload)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('createCampaignType error', response.data)
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		updateCampaignType({ dispatch }, { id, payload }) {
			return APIService.updateCampaignType(id, payload)
				.then(response => {
					if (response && response.data.status == 'error') {
						console.warn('updateCampaignType error', response.data)
					} else {
						dispatch('myBrands')
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		deleteCampaignType({ dispatch }, id) {
			return APIService.deleteCampaignType(id)
				.then(response => {
					if (response && response.data.status == 'error') {
						console.warn('updateCampaignType error', response.data)
					} else {
						dispatch('myBrands')
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		moveCampaignType({ dispatch }, { id, payload }) {
			return APIService.moveCampaignType(id, payload)
				.then(response => {
					if (response && response.data.status == 'error') {
						console.warn('moveCampaignType error', response.data)
					} else {
						dispatch('myBrands')
					}
					return response
				})
				.catch(error => console.warn(error))
		},

		// DEFAULT CAMPAIGNTYPE SIZE
		getCampaignTypeSizes({ commit }, id) {
			APIService.getCampaignTypeSizes(id)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('getCampaignTypeSizes error', response.data)
					}
					commit(
						'setDefaultCampaignTypeSizes',
						response.data.data.defaultCampaignTypeSizes
					)
				})
				.catch(error => console.warn(error))
		},

		// ORDERS
		getCampaignOrdersByBrand({ commit }, brandId) {
			commit('setCampaignOrders', null)
			return APIService.getCampaignOrdersByBrand(brandId)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('getCampaignOrderByBrand error', response.data)
					}
					commit('setCampaignOrders', response.data.data.campaignOrders)
					return response
				})
				.catch(error => console.warn(error))
		},
		getCampaignOrderWithReport({ state, dispatch }, id) {
			return APIService.getCampaignOrderWithReport(id)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('getCampaignOrderWithReport error', response.data)
					}
					if (
						state.activeBrand.id != response.data.data.campaignOrder.brandId
					) {
						console.info(
							'%c Active brand and campaign brand mismatch, changeing active brand ',
							'color: blue;background-color: yellow;'
						)
						dispatch('myBrands', response.data.data.campaignOrder.brandId)
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		getCampaignOrder({ state, dispatch }, id) {
			return APIService.getCampaignOrder(id)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('getCampaignOrder error, for id:' + id, response.data)
					}
					if (
						state.activeBrand.id != response.data.data.campaignOrder.brandId
					) {
						console.info(
							'%c Active brand and campaign brand mismatch, changeing active brand ',
							'color: blue;background-color: yellow;'
						)
						dispatch('myBrands', response.data.data.campaignOrder.brandId)
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		createCampaignOrder({ dispatch }, payload) {
			return APIService.createCampaignOrder(payload)
				.then(response => {
					dispatch('myBrands')
					return response
				})
				.catch(error => console.warn(error))
		},
		createCampaignOrders({ dispatch }, payload) {
			return APIService.createCampaignOrders(payload)
				.then(response => {
					dispatch('myBrands')
					return response
				})
				.catch(error => console.warn(error))
		},
		updateCampaignOrder(context, { id, payload }) {
			return APIService.updateCampaignOrder(id, payload)
				.then(response => {
					return response
				})
				.catch(error => console.warn(error))
		},
		cancelCampaignOrder(context, orderId) {
			return APIService.cancelCampaignOrder(orderId)
				.then(response => {
					return response
				})
				.catch(error => console.warn(error))
		},
		getCampaignOrderAd(context, campaignOrderId) {
			return APIService.getCampaignOrderAd(campaignOrderId)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('getCampaignOrderAd error', response)
					}

					return response
				})
				.catch(error => console.warn(error))
		},
		fetchBillableCampaignOrders(context, payload) {
			return APIService.fetchBillableOrders(payload)
				.then(response => {
					if (response.data.status == 'error') {
						console.warn('fetchBillableCampaignOrders error', response.data)
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		getCounties() {
			return APIService.getCounties()
				.then(response => {
					return response
				})
				.catch(error => console.warn(error))
		},

		// Import & Export
		importCompanies(context, payload) {
			return APIService.importCompanies(payload)
				.then(response => response)
				.catch(error => console.warn(error))
		},

		// USER
		getSelf({ commit }) {
			return APIService.getSelf()
				.then(response => {
					commit('setUser', response.data.data.user)
					return response
				})
				.catch(error => console.warn(error))
		},
		updateSelf(context, payload) {
			return APIService.updateSelf(payload)
				.then(response => response)
				.catch(error => console.warn(error))
		},
		registerUser(context, { formData, token }) {
			return APIService.registerUser(formData, token)
				.then(response => response)
				.catch(error => console.warn(error))
		},
		getUserAccessInvite(context, token) {
			return APIService.getUserAccessInvite(token)
				.then(response => response)
				.catch(error => console.warn(error))
		},
		inviteUserToBrand(context, payload) {
			return APIService.inviteUserToBrand(payload)
				.then(response => {
					if (response.data.status == 'success' && response.data.data.brand) {
						context.state.activeBrand = response.data.data.brand
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		inviteUserToCompany(context, payload) {
			return APIService.inviteUserToCompany(payload)
				.then(response => {
					if (response.data.status == 'success' && response.data.data.company) {
						context.state.activeCompany = response.data.data.company
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		cancelAccessInvite(context, id) {
			return APIService.cancelAccessInvite(id)
				.then(response => {
					if (response.data.status == 'success' && response.data.data.company) {
						context.state.activeCompany = response.data.data.company
					}
					if (response.data.status == 'success' && response.data.data.brand) {
						context.state.activeBrand = response.data.data.brand
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		respondAccessInvite({ commit }, { formData }) {
			return APIService.respondAccessInvite(formData)
				.then(response => {
					if (response.data.status == 'success' && response.data.data.user) {
						commit('setUser', response.data.data.user)
					}
					return response
				})
				.catch(error => console.warn(error))
		},
		deleteAccess({ commit, dispatch }, id) {
			return APIService.deleteAccess(id)
				.then(response => {
					if (response.data.status != 'success') return response
					else if (response.data.data.company) {
						commit('setActiveCompany', response.data.data.company)
					} else if (response.data.data.brand) {
						commit('setActiveBrand', response.data.data.brand)
					} else if (response.data.data.user) {
						commit('setUser', response.data.data.user)
						dispatch('myBrands')
					}
				})
				.catch(error => console.warn(error))
		},
	},
})

function localStorageParse(item) {
	if (!item) return null
	let itemValue = localStorage.getItem(item)
	if (!itemValue || itemValue == 'undefined') return null
	return itemValue ? JSON.parse(itemValue) : null
}
